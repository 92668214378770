const min = (px: number) => `@media (min-width: ${px}px)`
const max = (px: number) => `@media (max-width: ${px}px)`

export const media = {
  min,
  max,
  small: '@media (min-width: 640px)' as const,
  maxSmall: '@media (max-width: 639px)' as const,
  medium: '@media (min-width: 768px)' as const,
  maxMedium: '@media (max-width: 767px)' as const,
  mediarge: '@media (min-width: 900px)' as const,
  maxMediarge: '@media (max-width: 899px)' as const,
  large: '@media (min-width: 1024px)' as const,
  maxLarge: '@media (max-width: 1023px)' as const,
}
